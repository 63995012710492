import {useTracker} from '@/components/analytics/TrackerProvider';
import {useRouter} from 'next/router';
import {useEffect} from 'react';

/**
 * Hook for tracking page views on mount and route change
 */
export const useTrackPageViews = () => {
  const router = useRouter();
  const tracker = useTracker();

  /**
   * Registers an event handler for tracking page view events on route change
   */
  useEffect(() => {
    const handleRouteChange = () => tracker.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router.events, tracker]);
};
