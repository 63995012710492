import {createContext, useContext, useEffect, useState} from 'react';
import request from 'axios';

type TempLoginSession = {
  isTempSessionValid: boolean;
  email: string;
  loading: boolean;
  sessionToken: string | null;
};

const DEFAULT_STATE: TempLoginSession = {
  isTempSessionValid: false,
  email: '',
  loading: false,
  sessionToken: null,
};

const TempSessionContext = createContext<TempLoginSession>(DEFAULT_STATE);

const useTempSession = () => {
  const session = useContext(TempSessionContext);

  if (!session) {
    throw new Error(
      'TempSessionContext is missing in this context. Please make sure to use it within the TempSessionProvider sub-tree'
    );
  }

  return session;
};

type TempSessionActions = {
  updateSessionToken: (jwt: string) => void;
  clearSessionToken: () => void;
};

const TempSessionActionContext = createContext<TempSessionActions>({
  updateSessionToken: (jwt: string) => {},
  clearSessionToken: () => {},
});

const useTempSessionActions = () => {
  const actions = useContext(TempSessionActionContext);

  if (!actions) {
    throw new Error(
      'TempSessionActionContext is missing in this context. Please make sure to use it within the TempSessionProvider sub-tree'
    );
  }

  return actions;
};

const TempSessionProvider = ({children}: {children: React.ReactNode}) => {
  const [tempSession, setTempSession] =
    useState<TempLoginSession>(DEFAULT_STATE);

  useEffect(() => {
    const init = async () => {
      // Check if we have a token in local storage
      const token = localStorage.getItem('shibuyaTempSessionToken');
      if (token) {
        setTempSession({
          ...tempSession,
          sessionToken: token,
        });
      }
    };
    init();
  }, []);

  useEffect(() => {
    const checkSessionTokenValid = async () => {
      if (tempSession?.sessionToken) {
        setTempSession({
          ...tempSession,
          loading: true,
        });
        // Verify against the server
        const verifyRes = await request.get(
          '/api/platform/verify-temp-session',
          {
            headers: {
              'shib-temp-auth': `Temp ${tempSession.sessionToken}`,
            },
          }
        );
        if (verifyRes.data?.isValid) {
          setTempSession({
            isTempSessionValid: true,
            email: verifyRes.data?.email,
            loading: false,
            sessionToken: tempSession.sessionToken,
          });
        } else {
          // Delete the token if it's invalid
          localStorage.removeItem('shibuyaTempSessionToken');
          setTempSession(DEFAULT_STATE);
        }
      }
    };
    checkSessionTokenValid();
  }, [tempSession.sessionToken]);

  const updateSessionToken = (jwt: string) => {
    localStorage.setItem('shibuyaTempSessionToken', jwt);
    setTempSession({
      ...tempSession,
      sessionToken: jwt,
    });
  };

  const clearSessionToken = () => {
    setTempSession(DEFAULT_STATE);
    localStorage.removeItem('shibuyaTempSessionToken');
  };

  return (
    <TempSessionContext.Provider value={tempSession}>
      <TempSessionActionContext.Provider
        value={{updateSessionToken, clearSessionToken}}
      >
        {children}
      </TempSessionActionContext.Provider>
    </TempSessionContext.Provider>
  );
};

export {TempSessionProvider, useTempSession, useTempSessionActions};
