import React from 'react';
import Link from 'next/link';
import {useRouter} from 'next/router';

import {ModalWrapper} from '@/components/modals/Modal';

export const AcceptTosOverlay = ({
  visible,
  onAcceptTos,
}: {
  visible?: boolean;
  onAcceptTos: () => void;
}) => {
  const router = useRouter();

  if (router.pathname === '/game') {
    return null;
  }

  return (
    <ModalWrapper visible={visible} backdrop="bg-opacity-90">
      <div className="rounded bg-gray-900 py-4 px-4">
        <h1 className="mt-4 mb-4 text-2xl font-bold">Welcome to Shibuya</h1>
        <p className="mb-8 text-sm leading-relaxed text-gray-300">
          The terms and conditions of use set forth apply to this website
          (collectively, the &quot;Site&quot;). Please read these{' '}
          <Link href="/terms-of-service" legacyBehavior>
            <a
              className="font-medium text-white underline transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions
            </a>
          </Link>{' '}
          carefully before using the Site. By using this Site, you acknowledge
          that you have read, understood and agreed to the terms and conditions
          of use.
        </p>
        <button
          className={`flex w-full items-center justify-center rounded bg-gray-200 px-8 py-2 font-semibold text-gray-900 transition-all hover:bg-white`}
          onClick={onAcceptTos}
        >
          <span>Accept and Enter Site</span>
        </button>
      </div>
    </ModalWrapper>
  );
};

export default AcceptTosOverlay;
