import React, {createContext, useContext, useEffect, useState} from 'react';

type BannerProps = {
  fetchShouldShow: () => Promise<boolean>;
  message: string;
  cta?: string;
  onClick?: () => void;
};

type BannerContextType = BannerProps & {
  setBanner: (props: BannerProps) => void;
};

const BannerContext = createContext<BannerContextType>({
  fetchShouldShow: async () => false,
  message: '',
  setBanner: () => {},
});

export const Banner: React.FC = () => {
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const context = useBannerContext();

  const {fetchShouldShow, message, cta, onClick} = context;

  useEffect(() => {
    const checkShouldShow = async () => {
      if (fetchShouldShow) {
        const shouldShow = await fetchShouldShow();
        setShowBanner(shouldShow);
      }
    };
    checkShouldShow();
  }, [fetchShouldShow]);

  if (!context || !message || !showBanner) {
    return null;
  }

  return (
    <div className="h-fit w-full bg-blue-500 text-sm text-white py-2 px-4 text-center">
      <p>
        {message}
        {cta && onClick && (
          <button onClick={onClick} className="underline ml-2">
            {cta}
          </button>
        )}
      </p>
    </div>
  );
};

export const useBannerContext = () => {
  const context = useContext(BannerContext);

  if (context === undefined) {
    throw new Error('useBannerContext must be used within a BannerProvider');
  }

  return context;
};

export const BannerProvider: React.FC = ({children}) => {
  const [bannerProps, setBannerProps] = useState<BannerProps>({
    fetchShouldShow: async () => false,
    message: '',
  });

  return (
    <BannerContext.Provider value={{...bannerProps, setBanner: setBannerProps}}>
      {children}
    </BannerContext.Provider>
  );
};
