/* eslint-disable no-restricted-properties -- This should be the only file where we access process.env */

/**
 * Canonical location for all process.env access. Extract and export values from process.env here.
 * This ensures that we use the same values everywhere and reduces errors due to spelling mistakes
 * since process.env is untyped.
 */

const DEVELOPMENT_ENV = 'development';
const PREVIEW_ENV = 'preview';
const PRODUCTION_ENV = 'production';

/**
 * Prefer use of NEXT_PUBLIC_VERCEL_ENV over NODE_ENV.
 * NODE_ENV is "production" on preview environments, but we shouldn't treat it like a production environment.
 * For example, we wouldn't want to log production metrics on preview environments.
 */
export const ENVIRONMENT =
  process.env.NEXT_PUBLIC_VERCEL_ENV ?? DEVELOPMENT_ENV;
export const IS_PRODUCTION_ENV = ENVIRONMENT === PRODUCTION_ENV;
export const IS_DEVELOPMENT_ENV = ENVIRONMENT === DEVELOPMENT_ENV;
export const IS_PREVIEW_ENV = ENVIRONMENT === PREVIEW_ENV;

export const POSTHOG_API_KEY =
  process.env.POSTHOG_API_KEY ?? process.env.NEXT_PUBLIC_POSTHOG_API_KEY;

export const NEXT_PUBLIC_GOOGLE_ANALYTICS =
  process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS ?? 'G-JYS06H01KL';

export const NEXT_PUBLIC_DOMINIONX_RESULTS_ENABLED = !!Number(
  process.env.NEXT_PUBLIC_DOMINIONX_RESULTS_ENABLED
);

export const NEXT_PUBLIC_ETH_NETWORK = process.env.NEXT_PUBLIC_ETH_NETWORK;
export const NEXT_PUBLIC_ETH_PROVIDER = process.env.NEXT_PUBLIC_ETH_PROVIDER;

export const NEXT_LAVA_GAME_IS_DISABLED = !!Number(
  process.env.NEXT_LAVA_GAME_IS_DISABLED
);

export const NEXT_PUBLIC_PRODUCER_PASS_STAKING_CONTRACT_ADDRESS =
  process.env.NEXT_PUBLIC_PRODUCER_PASS_STAKING_CONTRACT_ADDRESS;
