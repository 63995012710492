import {useEffect, useState} from 'react';

/**
 * Hook for simulating a timer. Given a number of milliseconds,
 * the hook returns a boolean indicating whether or not the number of
 * milliseconds has passed or not.
 */
export const useTimer = (milliseconds: number) => {
  const [hasTimerEnded, setHasTimerEnded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHasTimerEnded(true);
    }, milliseconds);
  }, [milliseconds]);

  return hasTimerEnded;
};
