import {useEffect} from 'react';
import {useRouter} from 'next/router';

/**
 * This hook exists to address an issue where the scroll locking imposed by the `Dialog` component
 * from the `@headlessui/react` library persists across route changes in the app.
 *
 * (Reference: https://github.com/tailwindlabs/headlessui/blob/0c213b514d3cec0270ee4dbb9523b18ab765afc6/packages/%40headlessui-react/src/components/dialog/dialog.tsx#L240)
 *
 * This hook ensures that the scroll locking styles are reset on every `routeChangeComplete` event.
 */
export const useResetModalStylesOnRouteChange = () => {
  const router = useRouter();

  useEffect(() => {
    const overflow = document.documentElement.style.overflow;
    const paddingRight = document.documentElement.style.paddingRight;

    const reset = () => {
      document.documentElement.style.overflow = overflow;
      document.documentElement.style.paddingRight = paddingRight;
    };

    router.events.on('routeChangeComplete', reset);

    return () => router.events.off('routeChangeComplete', reset);
  }, []);
};
