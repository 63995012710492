import {ClientTracker} from '@/utils/analytics/client/ClientTracker';
import {MockClientTracker} from '@/utils/analytics/client/MockClientTracker';
import {IClientTracker} from '@/utils/analytics/client/types';
import {IS_PRODUCTION_ENV} from '@/utils/env';

/**
 * Helper method for creating the correct tracker for the client.
 * Use this method instead of creating a MockClientTracker or ClientTracker directly.
 */
export const createClientTracker = ({
  isBot,
}: {
  isBot: boolean;
}): IClientTracker => {
  if (!IS_PRODUCTION_ENV || isBot) {
    return new MockClientTracker();
  }

  return new ClientTracker();
};
