import {createClientTracker} from '@/utils/analytics/client';
import {IClientTracker} from '@/utils/analytics/client/types';
import {usePrivy} from '@privy-io/react-auth';
import {createContext, useContext, useEffect, useState} from 'react';
import checkIsBot from 'isbot';
import {useTimer} from '@/utils/hooks/useTimer';

export const TrackerContext = createContext<IClientTracker | null>(null);

export const useTracker = (): IClientTracker => {
  const tracker = useContext(TrackerContext);

  if (!tracker) {
    throw new Error(
      'tracker is missing in this context. Please make sure to use it within the TrackerProvider sub-tree'
    );
  }

  return tracker;
};

type TrackerProviderProps = React.PropsWithChildren<{}>;

export const TrackerProvider: React.FC<TrackerProviderProps> = ({children}) => {
  const [tracker, setTracker] = useState<IClientTracker | null>();
  const {user: privyUser, ready: isPrivyReady} = usePrivy();
  const hasPrivyLoadTimerEnded = useTimer(2000);

  /**
   * useEffect to create and set the tracker. We wait on privy to be ready
   * because we don't want to instantiate the tracker without user data if
   * the user is authenticated. This ensures that we always log events with
   * the right user data.
   *
   * In a situation where privy either fails to load or takes too long, we
   * go ahead and create the ClientTracker without waiting for user info from privy.
   */
  useEffect(() => {
    const isPrivyReadyOrHasPrivyLoadTimerEnded =
      isPrivyReady || hasPrivyLoadTimerEnded;

    if (tracker || !isPrivyReadyOrHasPrivyLoadTimerEnded) {
      return;
    }

    const didPrivyTakeTooLongToLoad = !isPrivyReady && hasPrivyLoadTimerEnded;
    if (didPrivyTakeTooLongToLoad) {
      console.warn(
        'Privy loaded too slowly. Skipping the wait for Privy and creating the client tracker without user data'
      );
    }

    const isBot = checkIsBot(navigator.userAgent);
    const clientTracker = createClientTracker({isBot});
    setTracker(clientTracker);
  }, [hasPrivyLoadTimerEnded, isPrivyReady, tracker]);

  /**
   * useEffect to ensure that the tracker is in sync with the authenticated state
   * of the user.
   *
   * If the user is authenticated then we want to make sure that the
   * tracker has the user information.
   *
   * If the user is unauthenticated then we want to make sure we log out the user so
   * that unauthenticated events aren't tied to the previously authenticated user.
   */
  useEffect(() => {
    if (!tracker) {
      return;
    }

    if (privyUser) {
      tracker.identify({
        address: privyUser.wallet?.address,
        privyUserId: privyUser.id,
      });
    } else {
      tracker.logoutUser();
    }
  }, [privyUser, tracker]);

  /**
   * If the tracker hasn't been instantitated yet then it means that we're still waiting
   * on critical data necessary for the instantiation of the tracker. We return null
   * until it's instantitated.
   */
  if (!tracker) {
    return null;
  }

  return (
    <TrackerContext.Provider value={tracker}>
      {children}
    </TrackerContext.Provider>
  );
};
