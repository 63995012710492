import 'react-toastify/dist/ReactToastify.css';
import '../styles/globals.css';
import '../styles/tooltip.css';
import '../styles/gradients.css';
import '../styles/animations.css';
import '../styles/tiptap-editor.css';

import type {AppProps} from 'next/app';
import {ToastContainer} from 'react-toastify';
import {mainnet, sepolia} from 'viem/chains';
import {PrivyProvider} from '@privy-io/react-auth';
import {SpeedInsights} from '@vercel/speed-insights/next';

import {useResetModalStylesOnRouteChange} from '@/utils/modal';
import Head from 'next/head';
import React, {ReactElement, ReactNode} from 'react';
import {useRouter} from 'next/router';
import {Analytics} from '@vercel/analytics/react';

import AcceptTosOverlay from '@/components/AcceptTosOverlay';
import {PreviousPathProvider} from '@/components/PreviousPathProvider';
import {BannerProvider} from '@/components/redesign/useBanner';
import {useTrackPageViews} from '@/utils/analytics/client/useTrackPageViews';
import {TrackerProvider} from '@/components/analytics/TrackerProvider';
import {NextPage} from 'next';
import {TempSessionProvider} from '@/utils/auth/TempSessionProvider';

const COMPANY_DESCRIPTION =
  'Welcome to Shibuya, a platform for high potential short films. We provide creators with a full-stack solution to fund films and shorts, build community, and foster an engaged fanbase. Fans can fund their favorite films, participate in the creative process, and join communities for the films they love most.';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const WrappedComponent = ({Component, pageProps}: AppPropsWithLayout) => {
  const router = useRouter();
  const [shouldDisplayTos, setDisplayTosState] = React.useState(false);
  const [isPendingTos, setPendingTosState] = React.useState(true);
  // const bannerContext = useBannerContext();
  // const tracker = useTracker();
  // const setBanner = bannerContext?.setBanner;
  useTrackPageViews();

  const getLayout = Component.getLayout ?? ((page) => page);

  React.useEffect(() => {
    try {
      // These pages should always be public
      if (
        router.route === '/terms-of-service' ||
        router.route === '/privacy-policy'
      ) {
        return;
      }

      const isAccepted = !!Number(
        window.localStorage.getItem('shibuya.tos.accepted')
      );

      if (!isAccepted) {
        setDisplayTosState(true);
      }
    } catch (e) {
      setDisplayTosState(false);
      setPendingTosState(false);
    }
  }, [router]);

  // Uncomment for site-wide banner that overrides any other
  // page banner

  // React.useEffect(() => {
  //   if (user) {
  //     setBanner({
  //       fetchShouldShow: async () => {
  //         return !user?.email?.address;
  //       },
  //       message: 'Link email to be eligible for bonus $WRAB!',
  //       cta: 'Link email',
  //       onClick: () => {
  //         tracker.capture('banner.email.link');
  //         linkEmail();
  //       },
  //     });
  //   }
  // }, [user, setBanner, tracker, linkEmail]);

  const handleAcceptTos = () => {
    try {
      window.localStorage.setItem('shibuya.tos.accepted', '1');
    } catch (e) {
      console.warn('Local storage is unavailable.');
    } finally {
      setDisplayTosState(false);
      setPendingTosState(false);
    }
  };

  return (
    <>
      {getLayout(<Component {...pageProps} />)}
      {isPendingTos && (
        <AcceptTosOverlay
          visible={shouldDisplayTos}
          onAcceptTos={handleAcceptTos}
        />
      )}
    </>
  );
};

function App(appProps: AppPropsWithLayout) {
  // We do this to handle an issue with navigating from pages that don't reset styles
  // applied to the root html element (overflow: hidden + paddingRight) via the `Dialog`
  // component from the `@headlessui/react` library.
  useResetModalStylesOnRouteChange();

  const environment = process.env.NEXT_PUBLIC_STAGING
    ? 'staging'
    : process.env.NODE_ENV;

  const isLiveMode = environment === 'production';

  if (!process.env.NEXT_PUBLIC_PRIVY_APP_ID) {
    throw new Error('NEXT_PUBLIC_PRIVY_APP_ID must be set');
  }

  return (
    <>
      <Head>
        <title>Shibuya</title>
        <meta property="og:title" content="Shibuya" key="title" />
        <meta
          property="og:description"
          content={COMPANY_DESCRIPTION}
          key="description"
        />{' '}
        <meta
          property="og:image"
          content="https://shibuya.film/images/shibuya-thumbnail.jpg"
          key="og-image"
        />
      </Head>
      <ToastContainer autoClose={8000} theme="dark" />

      <PrivyProvider
        appId={process.env.NEXT_PUBLIC_PRIVY_APP_ID}
        config={{
          appearance: {
            theme: 'dark',
          },
          defaultChain: isLiveMode ? mainnet : sepolia,
        }}
      >
        <TempSessionProvider>
          <TrackerProvider>
            <PreviousPathProvider>
              <BannerProvider>
                <WrappedComponent {...appProps} />
              </BannerProvider>
            </PreviousPathProvider>
          </TrackerProvider>
        </TempSessionProvider>
      </PrivyProvider>
      <Analytics />
      <SpeedInsights />
    </>
  );
}

export default App;
