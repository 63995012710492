import {useRouter} from 'next/router';
import React, {useEffect, useState} from 'react';

interface PreviousPathContextValue {
  hasPreviousPath: boolean;
}

const PreviousPathContext = React.createContext<PreviousPathContextValue>({
  hasPreviousPath: false,
});

export const useHasPreviousPath = (): boolean => {
  const {hasPreviousPath} = React.useContext(PreviousPathContext);
  return hasPreviousPath;
};

/**
 * Provider for keeping track of whether or not there's a previous path.
 * This provides us with the ability to do things like showing a back button on the player page without ever
 * navigating away from our site.
 */
export const PreviousPathProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const router = useRouter();
  const [hasPreviousPath, setHasPreviousPath] = useState(false);

  useEffect(() => {
    const handleRouteChange = (newPath: string) => {
      const previousPath = router.asPath;

      if (!hasPreviousPath && newPath !== previousPath) {
        setHasPreviousPath(true);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [hasPreviousPath, router]);

  return (
    <PreviousPathContext.Provider value={{hasPreviousPath}}>
      {children}
    </PreviousPathContext.Provider>
  );
};
