import posthog from 'posthog-js';
import {POSTHOG_API_KEY} from '@/utils/env';
import {UserIdentity} from '@/utils/analytics/client/types';
import {AbstractClientTracker} from '@/utils/analytics/client/AbstractClientTracker';

/**
 * Client tracker to be used only in production environments because PostHog only has one environment.
 */
export class ClientTracker extends AbstractClientTracker {
  constructor() {
    super();

    if (!POSTHOG_API_KEY) {
      throw new Error('POSTHOG_API_KEY is missing');
    }

    posthog.init(POSTHOG_API_KEY, {api_host: 'https://app.posthog.com'});
  }

  capture(event: string, payload?: Record<string, unknown>) {
    posthog.capture(event, payload);
  }

  identify(userIdentity: UserIdentity) {
    super.identify(userIdentity, () => {
      const {privyUserId, address} = userIdentity;
      posthog.identify(privyUserId, {wallet: address, privyUserId});
    });
  }

  logoutUser() {
    super.logoutUser(() => {
      posthog.reset();
    });
  }
}
