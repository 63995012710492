import {AbstractClientTracker} from '@/utils/analytics/client/AbstractClientTracker';
import {UserIdentity} from '@/utils/analytics/client/types';

/**
 * Mock client tracker to be used in non-production environments.
 * Instead, it console.logs the payload for debugging purposes.
 */
export class MockClientTracker extends AbstractClientTracker {
  constructor() {
    super();
    this.log('initialized');
  }

  capture(event: string, payload?: Record<string, unknown>) {
    this.log('capture', {event, payload});
  }

  identify(userIdentity: UserIdentity) {
    super.identify(userIdentity, () => {
      this.log('identify', {userIdentity});
    });
  }

  logoutUser() {
    super.logoutUser(() => {
      this.log('logoutUser');
    });
  }

  log(...args: unknown[]) {
    console.log('[MockClientTracker]', ...args);
  }
}
