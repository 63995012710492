import {Fragment, MutableRefObject, useRef} from 'react';
import {Dialog, Transition} from '@headlessui/react';

export type ModalSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const getWidthClassesBySize = (size: ModalSize) => {
  switch (size) {
    case 'sm':
      return 'sm:max-w-lg';
    case 'md':
      return 'sm:max-w-2xl';
    case 'lg':
      return 'sm:max-w-4xl';
    case 'xl':
      return 'sm:max-w-6xl';
    case '2xl':
      return 'sm:max-w-7xl';
    default:
      return 'sm:max-w-lg';
  }
};

type WrapperProps = {
  className?: string;
  visible?: boolean;
  size?: ModalSize;
  backdrop?: string;
  children: React.ReactNode;
  onClose?: () => void;
  cancelButtonRef?: MutableRefObject<any>;
};

export const ModalWrapper = ({
  className = '',
  visible,
  size = 'sm',
  backdrop = '',
  onClose,
  cancelButtonRef,
  children,
}: WrapperProps) => {
  const fallbackRef = useRef(null);
  const defaultFocusRef = cancelButtonRef ?? fallbackRef;
  const w = getWidthClassesBySize(size);

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={defaultFocusRef}
        onClose={() => onClose?.()}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={`fixed inset-0 bg-black ${backdrop} transition-opacity`}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`${className} ${w} inline-block transform overflow-hidden rounded border border-white border-opacity-10 bg-white bg-opacity-10
              text-left align-bottom text-white shadow-xl backdrop-blur-md transition-all sm:my-8 sm:w-full sm:align-middle`}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

type ContentProps = {
  title?: string;
  header?: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
  onSuccess?: () => void;
  onCancel?: () => void;
};

export const ModalContent = ({
  title,
  header,
  body,
  footer,
  onCancel,
  onSuccess,
}: ContentProps) => {
  return (
    <div>
      {title ? (
        <div className="border-b border-white border-opacity-10 px-4 py-4">
          <h3 className="text-xl font-bold">{title}</h3>
        </div>
      ) : (
        header
      )}

      <div className="px-4 py-8">{body}</div>

      {footer || (
        <div className="flex justify-between border-t border-white border-opacity-10 px-4 py-4">
          <button
            className="mr-2 rounded bg-gray-800 px-4 py-1 text-center font-semibold text-gray-100 transition-colors hover:bg-gray-700 hover:text-white"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="rounded bg-gray-50 px-4 py-1 text-center  font-semibold text-gray-900 transition-colors hover:bg-gray-200"
            onClick={onSuccess}
          >
            OK
          </button>
        </div>
      )}
    </div>
  );
};

type Props = {
  visible?: boolean;
  size?: ModalSize;
  backdrop?: string;
  title?: string;
  header?: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
  onSuccess?: () => void;
  onCancel?: () => void;
};

export const Modal = ({
  visible,
  size = 'sm',
  title,
  header,
  body,
  footer,
  backdrop = 'bg-opacity-60',
  onSuccess,
  onCancel,
}: Props) => {
  const handleSuccess = () => onSuccess?.();
  const handleCancel = () => onCancel?.();

  return (
    <ModalWrapper
      visible={visible}
      size={size}
      backdrop={backdrop}
      onClose={handleCancel}
    >
      <ModalContent
        title={title}
        header={header}
        body={body}
        footer={footer}
        onCancel={handleCancel}
        onSuccess={handleSuccess}
      />
    </ModalWrapper>
  );
};

export default Modal;
