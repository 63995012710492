import {IClientTracker, UserIdentity} from '@/utils/analytics/client/types';
import isEqual from 'lodash.isequal';

export abstract class AbstractClientTracker implements IClientTracker {
  private userIdentity: UserIdentity | null = null;

  capture(event: string, payload?: Record<string, unknown>) {
    throw new Error('Must be implemented');
  }

  identify(userIdentity: UserIdentity, onIdentify: () => void) {
    if (this.isCurrentIdentifiedUser(userIdentity)) {
      return;
    }

    this.userIdentity = userIdentity;
    onIdentify?.();
  }

  logoutUser(onLogoutUser: () => void) {
    if (!this.hasIdentifiedUser()) {
      return;
    }

    this.userIdentity = null;
    onLogoutUser?.();
  }

  /**
   * Helper method for determining whether or not the passed in
   * user identity is the current identified user.
   */
  private isCurrentIdentifiedUser(userIdentity: UserIdentity) {
    return isEqual(this.userIdentity, userIdentity);
  }

  /**
   * Helper method for determining if there's an existing identified user.
   */
  private hasIdentifiedUser() {
    return this.userIdentity !== null;
  }
}
